import React from 'react'
import Img from 'gatsby-image'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, } from 'mdbreact'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const LogoCarousel = props => {
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <p className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-3 title-xs-medium title-large">
            {props.data.title}
          </p>
          {props.data.description &&
            <p className="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium text-center pb-5">
              {props.data.description}
            </p>
          }
        </MDBCol>
      </MDBRow>
      <MDBCarousel
        activeItem={1}
        length={Math.ceil(props.data.section.length / 4)}
        slide={true}
        showControls={false}
        showIndicators={true}
        multiItem
      >
        <MDBCarouselInner>
          {props.data.section.map((item, index) => (
            <ConditionalWrapper
              key={index}
              condition={index % 4 === 0}
              wrapper={children => (
                <MDBCarouselItem itemId={index === 0 ? 1 : index % 4 === 0 ? index / 4 + 1 : 0} >
                  {children}
                </MDBCarouselItem>
              )}
            >
              {index % 4 === 0 && (
                <MDBRow>
                  <MDBCol lg="3" md="6" xs="12" id={index}>
                    <div className="mb-2 text-center">
                      <a href={props.data.section[index].link} target="_blank" rel="noopener">
                        <Img fixed={props.data.section[index].image.childImageSharp.fixed} alt={props.data.section[index].alttext} className="text-center" />
                      </a>
                    </div>
                  </MDBCol>

                  {index + 1 < props.data.section.length && (
                    <MDBCol lg="3" md="6" xs="12" id={index + 1}>
                      <div className="mb-2 text-center">
                        <a href={props.data.section[index + 1].link} target="_blank" rel="noopener">
                          <Img fixed={props.data.section[index + 1].image.childImageSharp.fixed} alt={props.data.section[index + 1].alttext} className="text-center" />
                        </a>
                      </div>
                    </MDBCol>
                  )}

                  {index + 2 < props.data.section.length && (
                    <MDBCol lg="3" md="6" xs="12" id={index + 2}>
                      <div className="mb-2 text-center">
                        <a href={props.data.section[index + 2].link} target="_blank" rel="noopener">
                          <Img fixed={props.data.section[index + 2].image.childImageSharp.fixed} alt={props.data.section[index + 2].alttext} className="text-center" />
                        </a>
                      </div>
                    </MDBCol>
                  )}

                  {index + 3 < props.data.section.length && (
                    <MDBCol lg="3" md="6" xs="12" id={index + 3}>
                      <div className="mb-2 text-center">
                        <a href={props.data.section[index + 3].link} target="_blank" rel="noopener">
                          <Img fixed={props.data.section[index + 3].image.childImageSharp.fixed} alt={props.data.section[index + 3].alttext} className="text-center" />
                        </a>
                      </div>
                    </MDBCol>
                  )}
                </MDBRow>
              )}
            </ConditionalWrapper>
          ))}
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  )
}

export default LogoCarousel
