import React, { Component } from 'react'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBContainer, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { Link } from 'gatsby'

class NavBarPage extends Component {
  state = {
    collapsed: false,
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  componentDidMount() {
    const str = window.location.pathname.substr(
      1,
      window.location.pathname.length - 1
    )
    const len = str.indexOf('/')
    this.setState({
      parentNav: len < 0 ? str : str.substr(0, len),
    })
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.handleTogglerClick}
      />
    )

    const isActive = ({ isCurrent }) => {
      return isCurrent ? { className: 'nav-link active' } : {}
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent ? { className: 'nav-link active' } : {}
    }

    return (
      <div>
        <MDBNavbar
          color="top-nav-collapse"
          dark
          expand="lg"
          fixed="top"
          scrolling
          transparent
        >
          <MDBContainer>
            <MDBNavbarBrand>
              <Link to="/">
                <img
                  src="/unicom-engineering-logo-we.svg"
                  alt="Company logo"
                  title="UNICOM Engineering, Inc."
                  height="32"
                />
              </Link>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              aria-label="Mobile Navigation Button"
              onClick={this.handleTogglerClick}
            />
            <MDBCollapse isOpen={this.state.collapsed} navbar>
              <MDBNavbarNav right>
                <MDBNavItem>
                  <Link to="/platforms/" getProps={isPartiallyActive} className="nav-link" >
                    Platforms
                  </Link>
                </MDBNavItem>

                <MDBNavItem>
                  <Link to="/ai/" getProps={isPartiallyActive} className="nav-link" >
                    AI
                  </Link>
                </MDBNavItem>

                <MDBNavItem>
                  <Link to="/storage/" getProps={isPartiallyActive} className="nav-link" >
                    Storage
                  </Link>
                </MDBNavItem>

                <MDBNavItem>
                  <Link to="/liquid-cooling/" getProps={isPartiallyActive} className="nav-link" >
                    Liquid cooling
                  </Link>
                </MDBNavItem>

                <MDBNavItem>
                  <Link to="/data-center/" getProps={isPartiallyActive} className="nav-link" >
                    Data center
                  </Link>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret className={ this.state.parentNav === 'services' ? 'active' : '' } >
                      <span className="mr-1">Services</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown">
                      <MDBDropdownItem href="/services/">
                        OEM Services
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/design-engineering/">
                        - Solution design
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/system-integration/">
                        - System integration
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/server-branding/">
                        - Server branding
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/logistics-and-compliance/">
                        - Logistics and compliance
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/regulatory-compliance/">
                        - Regulatory compliance
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/global-support/">
                        - Global support
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/business-analytics/">
                        - Business analytics
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/services/virtual-oem-program/">
                        - Virtual OEM program
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>

                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle
                      nav
                      caret
                      className={
                        (this.state.parentNav === 'about' || 
                         this.state.parentNav === 'blog' || 
                         this.state.parentNav === 'careers' || 
                         this.state.parentNav === 'contact' || 
                         this.state.parentNav === 'events' || 
                         this.state.parentNav === 'industries' || 
                         this.state.parentNav === 'news' || 
                         this.state.parentNav === 'quality' || 
                         this.state.parentNav === 'resources' || 
                         this.state.parentNav === 'support' || 
                         this.state.parentNav === 'technology-partners')  
                         ? 'active' : ''
                      }
                    >
                      <span className="mr-1">Company</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown">
                      <MDBDropdownItem href="/contact/">
                        Contact us
                      </MDBDropdownItem>
                      <hr className="nav" />
                      <MDBDropdownItem href="/blog/">
                        Blog
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/news/">
                        Newsroom
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/resources/">
                        Resources
                      </MDBDropdownItem>
                      <hr className="nav" />
                      <MDBDropdownItem href="/about/">
                        About Us
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/careers/">
                        Careers
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/industries/">
                        Industries
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/about/leadership/">
                        Leadership
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/quality/">
                        Quality
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/support/">
                        Support
                      </MDBDropdownItem>
                      <MDBDropdownItem href="/technology-partners/">
                        Technology partners
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                <Link to="/search/" className="nav-link d-none d-lg-block" aria-label="Search">
                    <FontAwesomeIcon icon={faSearch} />
                  </Link>
                  <Link to="/search/" className="nav-link d-lg-none d-sm-block" aria-label="Search">
                    Search
                  </Link>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        {this.state.collapsed && overlay}
      </div>
    )
  }
}

export default NavBarPage
