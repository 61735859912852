import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternating from '../../components/columnAlternating'
import Cta from '../../components/cta'
import Card from '../../components/card'
import CardIcons from '../../components/cardIcons.js'
import LogoCarousel from '../../components/logoCarousel'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Showdown from 'showdown'

library.add(fat)

export default ({ data, location }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white mb-n5">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-white mt-n5">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <LogoCarousel data={post.frontmatter.partnerlogos} />            
          </MDBAnimation>  
        </section>        


        {post.frontmatter.requirement &&
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <div className="solution-text">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">{post.frontmatter.requirement.title}</h2>
                  <p className="pb-5 font-w-400 text-medium">{post.frontmatter.requirement.description}</p>

                  {post.frontmatter.requirement.section &&
                    <>
                      {post.frontmatter.requirement.section.map((sections, index) => (
                        <ColumnAlternating
                          key={index}
                          textCol={'7'}
                          imageCol={'5'}
                          title={sections.title}
                          subtitle={sections.subtitle}
                          subtitlecolour={sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue'}
                          description={converter.makeHtml(sections.description)}
                          imageUrl={sections.image.childImageSharp.fluid}
                          imageAltText={sections.alttext}
                          placement={sections.placement}
                          titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                          link={sections.btnlink}
                          colour={sections.btncolour}
                        />
                      ))}
                    </>
                  }
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        }

        {post.frontmatter.insight && 
          <>
            {post.frontmatter.insight.map((insights, index) => (
              <section className="bg-white" key={index}>
                <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="12">
                        <h2 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3 ${!insights.description && "text-center"}`}>{insights.title}</h2>

                        {insights.description &&
                          <div
                            className="mt-3 richtext richtext-image divlink"
                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(insights.description), }}
                          />                  
                        }
                      </MDBCol>
                    </MDBRow>

                    {insights.image &&
                      <MDBRow>
                        <MDBCol lg="12" className="pt-5 text-center image-center">
                          <Img
                            fluid={insights.image.childImageSharp.fluid}
                            alt={insights.alttext}
                            className="alt-bg-image rounded w-850"
                          />
                        </MDBCol>
                      </MDBRow>
                    }
                  </MDBContainer>
                </MDBAnimation>
              </section>
            ))}
          </>
        }

        <section className="bg-white-grey-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.whyworkuei.title}
              </h3>
              {post.frontmatter.whyworkuei.whywork && 
                <MDBRow>
                  <>
                    {post.frontmatter.whyworkuei.whywork.map((workwith, index) => (
                      <CardIcons
                        key={index}
                        collg="4"
                        colmd="6"
                        title={workwith.title}
                        imageUrl={workwith.image.childImageSharp.fixed}
                        imageAltText={workwith.alttext}
                      />
                    ))}
                  </>
                </MDBRow>
              }
            </MDBContainer>
          </MDBAnimation>
        </section>

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.simplify.title}
                ctadescription={post.frontmatter.simplify.description}
                ctalink={post.frontmatter.simplify.link}
                ctalinktext={post.frontmatter.simplify.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>

        {post.frontmatter.helpfulresources && 
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && 
                  <MDBRow className="pt-5">
                    {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                      <Card
                        key={index}
                        collg={(post.frontmatter.helpfulresources.helpfulres.length % 3 === 0) ? "4" : (post.frontmatter.helpfulresources.helpfulres.length % 4 === 0) ? "3" : "4" }
                        colmd="6"
                        height="9.5rem"
                        title={helpfulres.title}
                        subtitle={helpfulres.subtitle}
                        description={helpfulres.description}
                        imageUrl={helpfulres.image.childImageSharp.fluid}
                        imageAltText={helpfulres.alttext}
                        placement={helpfulres.placement}
                        link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                        titleclass="title-small"
                        descriptionClass="text-card-small"
                      />
                    ))}
                  </MDBRow>
                }
              </MDBContainer>
            </MDBAnimation>
          </section>
        }

        <section className="bg-blue-green-gradient">
          <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "data-center" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        openingpara
        partnerlogos {
          title
          description
          section {
            image {
              childImageSharp {
                fixed(height: 100, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            alttext
            link
          }
        }        
        requirement {
          title
          description
          section {
            title          
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            placement
            alttext
            btnlink
            btncolour
        }
      }
        podcast {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 720, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
          placement
        }
        whyworkuei {
          title
          whywork {
            title
            alttext
            image {
              childImageSharp {
                fixed(width: 70, height: 70, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        insight {
          anchorlink
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
        }
        simplify {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                fluid(maxWidth: 615, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`